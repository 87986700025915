<template>
  <img
    :src="src"
    :width="_width"
    :height="_height"
    draggable="false"
    loading="lazy"
    decoding="async"
  />
</template>

<script>
import { optimizedImageURL, getWidth, getHeight } from '@/services/OptimizedImage'

export default {
  name: 'ResponsiveImage',
  props: {
    data: { type: Object, required: true },
    width: { type: Number, default: 1200 }
  },
  computed: {
    _width () {
      return getWidth(this.data)
    },
    _height () {
      return getHeight(this.data)
    },
    src () {
      return optimizedImageURL(this.data, { width: this.width })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  user-select: none;
}
</style>
